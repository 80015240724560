import React, { useState } from "react";
import { Stack, Box, Button, TextField, Grid, Tooltip } from "@mui/material";
import html2canvas from "html2canvas";
//Srcs
import "./pagestyle.scss";
import CropImageFile from "../Components/CropImage/CropImageFile";
import FramImage from "../Assets/Facebook-Fram-2024-2025.png";
import EmptyImage from "../Assets/empty-image.png";
import { saveAs } from 'file-saver';

export default function FrameImage() {
  // ========================= Upload Image =========================
  const [loading, setLoading] = useState(false);
  const [checkDownLoad, setCheckDownLoad] = useState(false);
  const [profileHook, setProfileHook] = useState("");
  const [imageUrl, setImageUrl] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  // ======================== handleDownload image =========================

  const handleCheckDownload = () => {
    setCheckDownLoad(true);
    setLoading(true)
    setTimeout(() => {
      handleDownloadImage();
    }, 1500);
  };

  const printRef = React.useRef();

  const handleDownloadImage = async () => {
  
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/png");
 
    // Convert data URL to blob
    const blob = await fetch(data).then((res) => res.blob()); 
    
    // Check if the current browser is Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
       
        const link = document.createElement("a");

        if (typeof link.download === "string") {
          link.href = data;
          link.download = "image.png";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          setImageUrl(data);
        } else {
          window.open(data);
        }

        setInfoMessage('Image downloaded! Check your Downloads folder and move it to your gallery.');
    
    } else {
      // Use FileSaver for other browsers
      saveAs(blob, 'image.png');
      setInfoMessage('Image downloaded! Check your Downloads folder.');
    }

    setTimeout(() => {
      setCheckDownLoad(false);
      setLoading(false)
      setOpenCrop(false)
    }, 2000);
    
  };


  const handleShare = async () => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Check file size (example limit: 5MB)
      const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
      if (blob.size > MAX_FILE_SIZE) {
        alert('File is too large to share. Please use a smaller image.');
        return;
      }

      if (navigator.canShare && navigator.canShare({ files: [blob] })) {
        await navigator.share({
          files: [new File([blob], 'image.png', { type: 'image/png' })],
          title: 'Check out this image',
          text: 'Here is an image I created!',
        });
      } else {
        alert('Sharing not supported on this browser.');
      }
    } catch (err) {
      console.error('Error sharing the image:', err);
      alert('An error occurred while sharing the image. Please try again.');
    }
  };


  return (
    <Stack
      direction="column"
      justifyContent="center"
      className="page-container"
    >
      <Stack direction="row" justifyContent="center" width="100%">
        <Box>              
          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12}>
                            
              { checkDownLoad ? (
                <Stack direction="row" justifyContent="center">
                  <Box className="image-container-download-preview" ref={printRef}>
                    <img
                      src={URL.createObjectURL(imageFile)}
                      className="avatar-image-download-preview"
                    />
                    <img src={FramImage} className="frame-image-download-preview" />
                  </Box>
                </Stack>
              ) : (
                <Stack direction="row" justifyContent="center">                  
                  {!openCrop ? (
                    <Tooltip title="click to upload">
                      <Button component="label" className="btn-container">
                        <TextField
                          type="file"
                          id="image"
                          sx={{ display: "none" }}
                          onChange={handleUploadImage}
                        />                        
                        { imageFile ? (
                          <Box className="image-container">                            
                            <img
                              src={URL.createObjectURL(imageFile)}
                              className="avatar-image"
                            />
                            <img src={FramImage} className="frame-image" />
                          </Box>
                        ) : (
                          <Box className="image-container">                            
                            <img className="avatar-image" src={EmptyImage} />
                            <img src={FramImage} className="frame-image" />
                          </Box>
                        )}
                      </Button>
                    </Tooltip>
                  ) : (
                    <CropImageFile
                      openCrop={openCrop}
                      setOpenCrop={setOpenCrop}
                      photoURL={photoURL}
                      setPhotoURL={setPhotoURL}
                      setImageFile={setImageFile}
                      setProfileHook={setProfileHook}
                    />
                  )}
                </Stack>
              )}

            </Grid>

            {imageFile && !openCrop ? (
              <Grid item xs={12} display="flex" justifyContent="center">
                {loading ? (
                  <Stack direction="row" justifyContent="center" sx={{paddingRight: "20px" , paddingLeft: "20px"}}>
                    <Button className="btn-download" fullWidth>
                      Loading...
                    </Button>
                  </Stack>
                ) : (
                  <Stack direction="row" justifyContent="center" sx={{paddingRight: "20px" , paddingLeft: "20px"}}>
                    <Button
                      className="btn-download"
                      fullWidth
                      onClick={handleCheckDownload}
                    >
                      Download
                    </Button>
                  </Stack>
                )}
              </Grid>
            ) : (
              ""
            )}


            {/* <Stack direction="row" justifyContent="center" width="100%">   
              {imageUrl && (
                <div>
                  <h3>Your Image:</h3>
                  <img src={imageUrl} alt="Downloaded" style={{ maxWidth: '100%', height: 'auto' }} />
                  <button onClick={handleShare}>Share Image</button>
                </div>
              )}
            </Stack> */}

            <Stack direction="row" justifyContent="center" width="100%">   
              {infoMessage && <p style={{color: 'black' , fontSize: "10px" , textAlign: "center"}}>{infoMessage}</p>} 
            </Stack>

          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
}
