import { Button, Stack } from '@mui/material'
import React from 'react'

export default function Home() {
    const openNewLink = () => {
        window.open("https://frames.go-globalschool.com/frame","_blank");
        // window.open("http://192.168.2.11:3011/frame","_blank");
        // window.open("http://192.168.2.11:3011/frame","_system");
    }
    
    return (
        <>
            <Stack direction="row" justifyContent="center" marginTop="150px" >
                <Button variant="contained" onClick={() => openNewLink()}>
                    បង្កើតស៊ុមរូបភាព
                </Button> 
            </Stack>
            <Stack direction="row" justifyContent="center" width="100%" marginTop="15px">   
                <p style={{color: 'black' , fontSize: "15px" , textAlign: "center"}}>
                    Please open with Safari or Google Chrome.
                </p> 
            </Stack>
            <Stack direction="row" justifyContent="center" width="100%" marginTop="-25px">  
                <p style={{color: 'black' , fontSize: "15px" , textAlign: "center"}}>
                    Easy download Image
                </p>
            </Stack>
        </>
    )
}
