import React, { useState } from "react";
import { Box, Button, Slider, Typography, Stack } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import CropIcon from "@mui/icons-material/Crop";
import Cropper from "react-easy-crop";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import moment from "moment";
//Srcs
import "./cropimagefile.scss";
import getCroppedImg from "./Crop";

export default function CropImageFile({
  openCrop,
  setOpenCrop,
  photoURL,
  setPhotoURL,
  setImageFile,
  setProfileHook,
}) {
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const newDate = moment(new Date()).format("MMdYYYY");

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      setLoading(true);

      setTimeout(() => {
        setPhotoURL(url);
        setImageFile(file);
        setOpenCrop(false);
        uploadImage(file);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  // compress image in customer
  const uploadImage = async (file) => {
    if (!file) return;

    const options = {
      maxSizeMB: 10,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);
    
    let newName = `${uuidv4()}${newDate}${file.name.split(".").pop()}`;
    var newFile = new File([compressedFile], `${newName}.png`, {
      type: "image/png",
    });
    setProfileHook(newFile);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Stack direction="row" justifyContent="center">
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
          cropShape="round"
        />
      </Stack>

      <Box sx={{ width: "100%" }}>
        <Box>
          <Typography>Zoom: {zoomPercent(zoom)}</Typography>
          <Slider
            valueLabelDisplay="auto"
            valueLabelFormat={zoomPercent}
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </Box>
        <Box>
          <Typography>Rotation: {rotation + "°"}</Typography>
          <Slider
            valueLabelDisplay="auto"
            min={0}
            max={360}
            value={rotation}
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="outlined"
          startIcon={<Cancel />}
          onClick={() => {
            setImageFile("");
            setOpenCrop(false);
          }}
        >
          Cancel
        </Button>
        {loading ? (
          <Button variant="contained" startIcon={<CropIcon />}>
            Uploading...
          </Button>
        ) : (
          <Button
            variant="contained"
            startIcon={<CropIcon />}
            onClick={cropImage}
          >
            Crop
          </Button>
        )}
      </Box>
    </Box>
  );
}

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
